<template>
  <div>
    <a-modal
      v-model="isShow"
      centered
      :mask-closable="false"
      title="打印二维码"
    >
      <span style="margin-left:20px;">此订单包含以下二维码</span>
      <a-row style="margin-top: 30px;">
        <a-col :span="12">
          <qrcode-vue
            v-if="url"
            :value="url"
            :size="200"
            level="H"
            id="print-qr"
          />
        </a-col>
        <a-col :span="10">
          <a-descriptions
            :column="1"
            class="custom-descriptions dead-information"
          >
            <a-descriptions-item label="逝者姓名" class="dead-name">
              {{ orderRecord.death_name }}
            </a-descriptions-item>
            <a-descriptions-item label="逝者性别" class="dead-sex">
              {{ orderRecord.gender }}
            </a-descriptions-item>
          </a-descriptions>
        </a-col>
      </a-row>
      <div id="templateDesignDiv" />
      <template slot="footer">
        <a-button class="ant-btn-default" @click="closeModal">
          关闭
        </a-button>
        <a-button class="ant-btn-primary" @click="print" :disabled="loadingPDF">
          打印
        </a-button>
      </template>
    </a-modal>
  </div>
</template>
<script>
import { findServiceOrderQrCodeUrl } from '@/api/order'
// import printJS from 'print-js'

export default {
  name: 'PrintQr',
  components: {
    QrcodeVue: () => import('qrcode.vue')
  },
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    orderRecord: {
      type: Object,
      default: function() {
        return {}
      }
    },
    id: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      loadingPDF: false,
      url: '',
      myPanel: {
        panels: [
          {
            index: 0,
            height: 260,
            width: 28,
            paperHeader: 67.5,
            paperFooter: 737.0078740157481,
            printElements: [
              {
                options: {
                  left: 18,
                  top: 0,
                  height: 36,
                  width: 52.5,
                  field: 'logo'
                },
                printElementType: { type: 'image' }
              },
              {
                options: {
                  left: -25,
                  top: 35,
                  height: 11,
                  width: 80,
                  field: 'no',
                  testData: '',
                  transform: 90,
                  fontFamily: 'Microsoft YaHei',
                  color: '#000000'
                },
                printElementType: { type: 'text' }
              },
              {
                options: {
                  left: -7.5,
                  top: 70,
                  height: 9.75,
                  width: 72,
                  title: '年龄',
                  field: 'age',
                  testData: '',
                  transform: 90,
                  fontFamily: 'Microsoft YaHei',
                  color: '#000000'
                },
                printElementType: { type: 'text' }
              },
              {
                options: {
                  left: 9,
                  top: 70,
                  height: 6,
                  width: 72,
                  title: '性别',
                  field: 'sex',
                  testData: '',
                  transform: 90,
                  fontFamily: 'Microsoft YaHei',
                  color: '#000000'
                },
                printElementType: { type: 'text' }
              },
              {
                options: {
                  left: 27,
                  top: 70,
                  height: 9.75,
                  width: 72,
                  title: '姓名',
                  field: 'name',
                  testData: '张三历史',
                  transform: 90,
                  fontFamily: 'Microsoft YaHei',
                  color: '#000000'
                },
                printElementType: { type: 'text' }
              },
              {
                options: {
                  left: -19.5,
                  top: 159.5,
                  height: 22.5,
                  width: 129,
                  title: '接运地点',
                  field: 'from',
                  testData: '南通市崇川区第一人民医院',
                  transform: 90,
                  fontFamily: 'Microsoft YaHei',
                  color: '#000000'
                },
                printElementType: { type: 'text' }
              },
              {
                options: {
                  left: -45,
                  top: 161,
                  height: 20,
                  width: 129,
                  title: '目的地',
                  field: 'to',
                  testData: '南通市崇川区安孝堂',
                  transform: 90,
                  fontFamily: 'Microsoft YaHei',
                  color: '#000000'
                },
                printElementType: { type: 'text' }
              },
              {
                options: {
                  left: -1.5,
                  top: 167,
                  height: 9.75,
                  width: 130.5,
                  title: '创单时间',
                  field: 'created',
                  testData: '2022-10-21 12:23',
                  transform: 90,
                  fontFamily: 'Microsoft YaHei',
                  color: '#000000'
                },
                printElementType: { type: 'text' }
              },
              {
                options: {
                  left: 19,
                  top: 235,
                  height: 45,
                  width: 45,
                  field: 'qr_url',
                  textType: 'qrcode',
                  transform: 90,
                  fontFamily: 'Microsoft YaHei',
                  color: '#000000',
                  testData: 'www.baidu.com'
                },
                printElementType: { type: 'text' }
              }],
            paperNumberLeft: 43,
            paperNumberTop: 715
          }]
      }
    }
  },
  computed: {
    isShow: {
      get() {
        return this.visible
      },
      set(val) {
        this.$emit('update:visible', val)
      }
    }
  },
  created() {
    this.fetchQrCodeUrl()
  },
  methods: {
    fetchQrCodeUrl() {
      findServiceOrderQrCodeUrl(this.id).then((res) => {
        this.url = res.data.url
      })
    },

    print() {
      if (this.loadingPDF) {
        return
      }
      this.$info({
        title: '正在打印，请耐心等候',
        content: ''
      })
      const printData = {
        logo: window.customConfig.printLogo,
        sex: this.orderRecord.gender,
        age: this.orderRecord.age,
        name: this.orderRecord.death_name,
        from: this.orderRecord.print_from,
        to: this.orderRecord.to,
        created: this.orderRecord.created_at_with_min,
        no: this.orderRecord.order_no,
        qr_url: this.url
      }
      console.log(printData)
      // eslint-disable-next-line no-undef
      hiprint.init()
      // eslint-disable-next-line new-cap,no-undef
      const t = new hiprint.PrintTemplate({
        template: this.myPanel,
        settingContainer: '#templateDesignDiv'
      })
      // 跳过预览直接打印，需软件支持
      t.print2(printData)
      this.loadingPDF = false
      // t.print(printData)
      // printJS('print-qr', 'html')
    },
    closeModal() {
      this.isShow = false
    }
  }
}
</script>
<style lang="less" scoped>
.dead-information {
  margin-top: 70px;
  margin-left: 20px;
  .dead-sex.dead-sex{
    .ant-descriptions-item-label{
      width: 100px;
    }
  }
}
</style>
